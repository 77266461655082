import React from "react"
import { Link, graphql } from "gatsby"

import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import PageTitle from "src/components/page-title"

import * as styles from "./WpCategory.module.css"

const BlogCategoryPage = ({ data }) => {

  const categories = data.allWpCategory.nodes

  const categoryName = data.wpCategory.name
  const categoryDescription = data.wpCategory.description
  const posts = data.wpCategory.posts.nodes
  return <>
    <SEO title={categoryName} description={categoryDescription} />
    <Header></Header>
    <main class="relative">
      <PageTitle
        preheader=""
        title={categoryName}
        subtitle={categoryDescription}
      ></PageTitle>

      <div class="relative py-16 bg-white overflow-hidden max-w-7xl mx-auto px-4">
        <ul class="w-full border-gray-300 flex flex-row flex-wrap md:flex-nowrap pb-16">
          <Link to="/blog" className="w-full text-center">
            <li class="border-gray-300 border-b md:border-l p-4 w-full font-bold text-oOrange hover:bg-gray-100 ">
              Blog Home
            </li>
          </Link>
          {categories.map(category => {
            return (
              <Link
                class="w-full text-center text-oOrange whitespace-nowrap"
                to={"/blog/" + category.slug}
                activeClassName="w-full text-center text-gray-900 whitespace-nowrap"
              >
                <li class="border-gray-300 border-b hover:bg-gray-100 p-4 cursor-pointer">
                  {category.name}
                </li>
              </Link>
            )
          })}
        </ul>
        <div class="">
          <ul class="grid grid-cols-1 md:grid-cols-3 gap-x-12 gap-y-12">
            {posts.map(post => {
              return (
                <li>
                  <a href={"/blog" + post.uri}>
                    <div className={"aspect-w-16 aspect-h-9"}>
                      {post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData.src && (
                        <img
                          src={
                            post.featuredImage?.node?.localFile
                              ?.childImageSharp?.gatsbyImageData.src
                          }
                        />
                      )}
                      {!post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData.src && (
                        <img
                          src={post.featuredImage?.node?.localFile?.publicURL}
                          alt={``}
                        />
                      )}
                    </div>
                    <h3>{post.title}</h3>
                    <div
                      dangerouslySetInnerHTML={{ __html: post.excerpt }}
                      className={styles.PostExcerpt}
                    />
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </>;
}

export default BlogCategoryPage

export const pageQuery = graphql`query CategoryData($id: String!) {
  wpCategory(id: {eq: $id}) {
    name
    description
    posts {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            sourceUrl
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
  allWpCategory(sort: {fields: name}, filter: {name: {ne: "Uncategorized"}}) {
    nodes {
      name
      slug
    }
  }
}
`
